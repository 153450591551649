const apiMethods = {
    BASE_REGIONS: '/locations/regions',
    COUNTRIES_PREDICTION: '/locations/countries',
    ACCOMMODATION_HTID: (htId) => `/accommodations/${htId}`,
    PAYMENT_PROCESSOR: '/payments/payment-processor',
    BOOKING_PAYMENT_PROCESSOR: (referenceCode) =>
        `/accommodations/bookings/${referenceCode}/payment-processor`,
    PAYFORT_CARDS_SIGN: '/cards/signatures/calculate',
    PAYFORT_PAY_NEW: (isService) =>
        `/payments/accommodations/${isService ? 'meal-services' : 'bookings'}/cards/new/pay`,
    NGENIUS_PAY: (referenceCode, isService) =>
        `/payments/accommodations/${isService ? 'meal-services' : 'bookings'}/${referenceCode}/pay`,
    PAYTABS_PAY: (referenceCode, isService) =>
        `/payments/accommodations/${isService ? 'meal-services' : 'bookings'}/${referenceCode}/pay-tabs/pay`,
    PAYFORT_PAY: (referenceCode, isService) =>
        `/payments/accommodations/${
            isService ? 'meal-services' : 'bookings'
        }/${referenceCode}/payfort/register`,
    PAYMENTS_CALLBACK: (isService) => (isService ? '/payments/meal-services/callback' : `/payments/callback`),
    NGENIUS_STATUS: (referenceCode, isService) =>
        `/payments/accommodations/${
            isService ? 'meal-services' : 'bookings'
        }/${referenceCode}/pay/refresh-status`,

    BOOK_BY_ACCOUNT: '/accommodations/bookings/book-by-account',
    BOOK_SEND_REQUEST: '/accommodations/bookings/send-booking-request',
    BOOK_FOR_OFFLINE: '/accommodations/bookings/book-for-offline',

    AGENT: '/agent',
    SURROGATE_AGENCIES: '/surrogate-agencies ',
    AGENT_REGISTER: '/agent/register',
    REGISTRATION_REQUEST: '/agency/request',
    AGENT_PROPERTIES: '/agent/properties',
    NOTIFICATIONS: '/notifications',
    NOTIFICATION_SETTINGS: '/notifications/settings',
    INVITATION_DATA: (invitationCode) => `/invitations/${invitationCode}`,
    AGENT_INVITE_SEND: '/agent/invitations/send',
    AGENT_INVITE_GENERATE: '/agent/invitations/generate',
    AGENT_INVITE_RESEND: (invitationId) => `/agent/invitations/${invitationId}/resend`,
    AGENT_INVITE_DISABLE: (invitationId) => `/agent/invitations/${invitationId}/disable`,
    AGENT_INVITATIONS: '/agent/invitations/not-accepted',
    AGENT_ACCEPTED_INVITES: '/agent/invitations/accepted',
    AGENCY_INVITATIONS: '/agency/invitations/not-accepted',
    AGENCY_ACCEPTED_INVITES: '/agency/invitations/accepted',
    AGENCY_ACCOUNTS: '/agency-accounts',
    AGENCY_ACCOUNT: (accountId) => `/agency-accounts/${accountId}`,
    AGENCY_ACCOUNT_DOWNLOAD: (accountId, fileType) => `/agency-accounts/${accountId}/${fileType}`,
    PREFERRED_CURRENCY: '/agent/preferred-currency',
    AGENCY_CURRENCIES: '/agency/system-settings/available-currencies',
    AGENT_ALL_ROLES: '/agent-roles',
    ACCOMMODATION_BOOKING: '/accommodations/bookings',
    A_BOOKING_FINALIZE: (referenceCode) => `/accommodations/bookings/${referenceCode}/finalize`,
    BOOKING_LIST: '/accommodations/bookings/agent',
    BOOKING_CANCEL: (bookingId) => `/accommodations/bookings/${bookingId}/cancel`,
    OFFLINE_BOOKING_CANCEL: (referenceCode) =>
        `/accommodations/bookings/offline/refcode/${referenceCode}/cancel`,
    BOOKING_PENALTY: (bookingId) => `/accommodations/bookings/${bookingId}/cancellation-penalty`,
    BOOKING_STATUS: (bookingId) => `/accommodations/bookings/${bookingId}/refresh-status`,
    BOOKING_SPECIAL_REQUEST: (referenceCode) =>
        `/accommodations/bookings/refcode/${referenceCode}/update-special-request`,
    BOOKING_HOTEL_CONFIRMATION_CODE: (referenceCode, isService) =>
        `/accommodations/${
            isService ? 'service-bookings' : 'bookings'
        }/${referenceCode}/hotel-confirmation-code`,
    BOOKING_INVOICE_PDF: (bookingId) =>
        `/accommodations/bookings/${bookingId}/supporting-documents/invoice/pdf`,
    SERVICE_INVOICE_PDF: (bookingId) => `/accommodations/service-bookings/${bookingId}/invoice/pdf`,
    BOOKING_ALL_INVOICES_ARCHIVE: (bookingId) =>
        `/accommodations/bookings/${bookingId}/supporting-documents/invoices/archive`,
    SERVICE_ALL_INVOICES_ARCHIVE: (bookingId) =>
        `/accommodations/service-bookings/${bookingId}/invoices/archive`,
    BOOKING_INVOICE_SEND: (bookingId) =>
        `/accommodations/bookings/${bookingId}/supporting-documents/invoice/send`,
    SERVICE_INVOICE_SEND: (bookingId) => `/accommodations/service-bookings/${bookingId}/invoice/send`,
    BOOKING_VOUCHER_PDF: (bookingId) =>
        `/accommodations/bookings/${bookingId}/supporting-documents/voucher/pdf`,
    BOOKING_ALL_VOUCHERS_ARCHIVE: (bookingId) =>
        `/accommodations/bookings/${bookingId}/supporting-documents/vouchers/archive`,
    BOOKING_VOUCHER_SEND: (bookingId) =>
        `/accommodations/bookings/${bookingId}/supporting-documents/voucher/pdf/send`,
    BOOKING_HISTORY: (bookingId, isService) =>
        `/accommodations/${isService ? 'service-bookings' : 'bookings'}/${bookingId}/status-history`,
    OFFLINE_BOOKING_HISTORY: (bookingId) => `/accommodations/bookings/${bookingId}/offline-status-history`,
    BOOKING_GET_BY_CODE: (referenceCode, isService) =>
        `/accommodations/${isService ? 'service-bookings' : 'bookings'}/refcode/${referenceCode}`,
    BOOKING_ROOM_CANCELLATION_AVAILABILITY: (referenceCode) =>
        `/accommodations/bookings/refcode/${referenceCode}/rooms-cancellation-allowed`,
    BOOKING_CANCEL_ROOM: (referenceCode) => `/accommodations/bookings/refcode/${referenceCode}/cancel-rooms`,
    BOOKING_PAY_WITH_CARD: (referenceCode, isService) =>
        `/accommodations/${
            isService ? 'offline-booking-services' : 'bookings'
        }/refcode/${referenceCode}/pay-with-credit-card`,
    AGENCY_BOOKINGS_LIST: '/accommodations/bookings/agency',
    ACCOMMODATION_DETAILS: (searchId, htId) =>
        `/accommodations/availabilities/searches/${searchId}/results/${htId}/accommodation `,
    A_SEARCH_ONE_CREATE: '/accommodations/availabilities/searches',
    A_SEARCH_ONE_CHECK: (searchId) => `/accommodations/availabilities/searches/${searchId}/state`,
    A_SEARCH_ONE_RESULT: (searchId) => `/accommodations/availabilities/searches/${searchId}`,
    A_SEARCH_ONE_RESULT_CHAINS: (searchId) => `/accommodations/availabilities/searches/${searchId}/chains`,
    A_SEARCH_ONE_RESULT_DISTRICTS: (searchId) =>
        `/accommodations/availabilities/searches/${searchId}/districts`,
    A_SEARCH_TWO_CHECK: (searchId, htId) =>
        `/accommodations/availabilities/searches/${searchId}/results/${htId}/state`,
    A_SEARCH_TWO_RESULT: (searchId, htId) =>
        `/accommodations/availabilities/searches/${searchId}/results/${htId}`,
    A_SEARCH_TWO_RESULT_GROUPED: (searchId, htId) =>
        `/accommodations/availabilities/searches/${searchId}/results/${htId}/grouped`,
    A_SEARCH_STEP_THREE: (searchId, htId, roomContractSetId) =>
        `/accommodations/availabilities/searches/${searchId}/results/${htId}/room-contract-sets/${roomContractSetId}`,
    REQUEST_DEADLINE: (searchId, htId, roomContractSetId) =>
        `/accommodations/availabilities/searches/${searchId}/results/${htId}/room-contract-sets/${roomContractSetId}/deadline`,
    PAYMENTS_HISTORY: `/agent/payments-history`,
    BASE_VERSION: '/versions',
    DIRECT_LINK_PAY: {
        SETTINGS: '/external/payment-links/payfort-payment-settings',
        GET_INFO: (code) => `/external/payment-links/${code}`,
        SIGN: '/external/payment-links/signatures/calculate',
        PAY: (code) => `/external/payment-links/${code}/pay`,
        NGENIUS_PAY: (code) => `/external/payment-links/${code}/ngenius/pay`,
        NGENIUS_STATUS: (code) => `/external/payment-links/${code}/ngenius/pay/refresh-status`,
        PAYTABS_PAY: (code) => `/external/payment-links/${code}/pay-tabs/pay`,
    },
    AGENCY: `/agency`,
    AGENCY_AGENTS: `/agency/agents`,
    AGENCY_AGENT: (agentId) => `/agency/agents/${agentId}`,
    AGENT_ENABLE: (agentId) => `/agency/agents/${agentId}/enable`,
    AGENT_DISABLE: (agentId) => `/agency/agents/${agentId}/disable`,
    AGENT_PAYMENT_SETTINGS: (agentId) => `/agency/agents/${agentId}/payment-settings`,
    AGENT_CHANGE_EMAIL_CONFIRMATION: (confirmationCode) => `/agent/change-email/${confirmationCode}`,
    CONTRACT_FILE: '/agency/contract-file',
    COMPANY_INFO: '/company',
    AGENT_SETTINGS: '/agent/settings/application',
    AGENT_ROLES: (agentId) => `/agency/agents/${agentId}/roles`,
    AGENCY_SETTINGS: `/agency/system-settings`,
    OUR_COMPANY: '/company',
    PROPERTY_BOOKING: (code) => `/property-owner/confirmations/${code}`,
    PROPERTY_HISTORY: (code) => `/property-owner/confirmations/${code}/confirmation-history`,
};

export default apiMethods;
